import axios from 'axios';
import {refreshToken} from "@/api/authApi";
import uuidv4 from 'uuid/v4';

// User相關的 api
const userRequest = axios.create({
  //baseURL: 'http://10.10.66.124/Hyireco/api'
  baseURL: '/HyirecoM/api/'
})

userRequest.defaults.timeout = 6000;

//=================事業單位==================
export const getOrgnizationList = (data, headers) => userRequest.post('/orgnization/getOrgnizationList', data, headers);
export const getOrgnizationData = (orgId, headers) => userRequest.get('/orgnization/' + orgId,headers);
export const delOrgnizationData = (orgId, headers) => userRequest.delete('/orgnization/' + orgId,headers);
export const saveOrgnization = (data, headers) => userRequest.post('/orgnization/saveOrgnization',data ,headers);
export const addOrgnization = (data, headers) => userRequest.post('/orgnization/addOrgnization',data ,headers);
export const getOrgnizationNameSelectOption = (headers) => userRequest.get('/orgnization/getOrgnizationNameSelectOption',headers);

//=================帳號=====================
export const getMemberList = (data, headers) => userRequest.post('/member/getMemberAccountList', data, headers);
export const getMemberData = (uuid, headers) => userRequest.get('/member/' + uuid, headers, headers);
export const delMember = (uuid, headers) => userRequest.delete('/member/' + uuid, headers, headers);
export const saveMember = (data, headers) => userRequest.post('/member/saveMemberAccount',data ,headers);
export const addMember = (data, headers) => userRequest.post('/member/addMemberAccount',data ,headers);

//=================子單位=======================
export const addSubUnit = (data, headers) => userRequest.post('/subUnit/addSubUnit',data ,headers);
export const saveSubUnit = (data, headers) => userRequest.post('/subUnit/saveSubUnit',data ,headers);
export const delSubUnit = (suId, headers) => userRequest.delete('/subUnit/' + suId, headers);

//=================事業單位人員==================
export const getOrgnizationMemberList = (data, headers) => userRequest.post('/orgnizationMember/getOrgnizationMemberList', data, headers);
export const getOrgnizationMemberData = (orgmId, headers) => userRequest.get('/orgnizationMember/' + orgmId,headers);
export const saveOrgnizationMember = (data, headers) => userRequest.post('/orgnizationMember/saveOrgnizationMember',data ,headers);
export const addOrgnizationMember = (data, headers) => userRequest.post('/orgnizationMember/addOrgnizationMember',data ,headers);
export const deleteOrgnizationMember = (data, headers) => userRequest.post('/orgnizationMember/deleteOrgnizationMember',data ,headers);

//=================場域==================
export const getJobSiteList = (data, headers) => userRequest.post('/jobSite/getJobSiteList', data, headers);
export const getJobSiteData = (jsId, headers) => userRequest.get('/jobSite/' + jsId,headers);
export const getActivityNotificationList = (jsId, headers) => userRequest.get('/activityNotification/getActivityNotificationList/' + jsId,headers);
export const sendNotification = (data, headers) => userRequest.post('/activityNotification/sendNotification', data, headers);
export const getNotificationRecordList = (anId, headers) => userRequest.get('/activityNotification/getNotificationRecordList/' + anId,headers);
export const saveJobSite = (data, headers) => userRequest.post('/jobSite/saveJobSite', data, headers);
export const addJobSite = (data, headers) => userRequest.post('/jobSite/addJobSite', data, headers);
export const delJobSite = (jsId, headers) => userRequest.delete('/jobSite/' + jsId, headers, headers);
export const getJobSiteMemberList = (jsId, headers) => userRequest.get('/jobSiteMember/getJobSiteMemberList/' + jsId,headers);
export const selectMember = (orgId, headers) => userRequest.get('/jobSiteMember/selectMember/' + orgId,headers);
export const saveSelectMember = (data, headers) => userRequest.post('/jobSiteMember/saveSelectMember', data, headers);
export const inOut = (data, headers) => userRequest.post('/jobSite/inOut', data, headers);
export const onlineAddMember = (data, headers) => userRequest.post('/jobSite/onlineAddMember', data, headers);
export const checkInOut = (data, headers) => userRequest.post('/jobSite/checkInOut', data, headers);
export const inOutInfo = (jsId, headers) => userRequest.get('/jobSite/inOutInfo/' + jsId,headers);
export const getJobSiteNameSelectOption = (orgId, headers) => userRequest.get('/jobSite/getJobSiteNameSelectOption/' + orgId,headers);
export const getHeatindex = (data, headers) => userRequest.post('/jobSite/getHeatindex', data, headers);
export const getDailyInout = (data, headers) => userRequest.post('/jobSite/getDailyInout', data, headers);

//====================入出場=====================
export const getInoutRecordList = (data, headers) => userRequest.post('/inoutRecord/getInoutRecordList', data, headers);
export const getInoutDetectRecordList = (data, headers) => userRequest.post('/inoutRecord/getInoutDetectRecordList', data, headers);
export const getJobSiteForInOut = (jsId, headers) => userRequest.get('/jobSite/getJobSiteForInOut/' + jsId,headers);

//=====================訓練======================
export const moreLearning = (drId, headers) => userRequest.get('/characteristic/moreLearning/' + drId,headers);
export const deepLearning = (data, headers) => userRequest.post('/characteristic/deepLearning', data,headers);

//=====================file======================
export const fileUpload = (data, headers) => userRequest.post('/file/upload',data ,headers);
export const downloadFile = (fileKey, headers) => userRequest.get('/file/upload/' + fileKey, headers);


//=====================參數======================
export const getSysParamListByCode = (code, headers) => userRequest.get('/sysParam/' + code ,headers);
export const getSysParamList = (data, headers) => userRequest.post('/sysParam/getSysParamList',data ,headers);
export const saveSysParam = (data, headers) => userRequest.post('/sysParam/saveSysParam',data ,headers);
export const addSysParam = (data, headers) => userRequest.post('/sysParam/addSysParam',data ,headers);
export const delSysParam = (paramId, headers) => userRequest.delete('/sysParam/' + paramId, headers);


export const callSmartPlug = (url) => axios.get(url);

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
    return config;
  }, function (error) {
  // Do something with request error
    return Promise.reject(error);
  });

//攔截403錯誤，更新token並重送request
userRequest.interceptors.response.use(undefined, async function(err){
  const error = err.response;
  var loginAuth = JSON.parse(window.sessionStorage.getItem('LoginAuth'));
  var responseData = err.response.config;
 
  if (error.status===403 && error.config && !error.config.__isRetryRequest) {
    // 取得新的token
    var apiPara = new URLSearchParams();
    apiPara.append("account", loginAuth.userID);
    apiPara.append("refreshId", loginAuth.refreshId);

    const csrfToken = uuidv4();
    const header = {
        header: {'X-XSRF-TOKEN': csrfToken} 
    }

      //console.log("test");
      await refreshToken(apiPara, header).then(function(response) {
        //console.log("refreshToken response = " + JSON.stringify(response));
        if ("201" == response.status) {
          loginAuth.id_token = response.data.id_token;
          responseData.headers.Authorization = "Bearer " + loginAuth.id_token;
          window.sessionStorage.setItem('LoginAuth', JSON.stringify(loginAuth));
          //console.log("refreshToken newloginAuth = " + JSON.stringify(loginAuth));
          //console.log("refreshToken responseData = " + JSON.stringify(responseData)); 
        }
      });
  
    return await axios.request(responseData);
  }
});